















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import debounce from 'lodash/debounce';
import parseJSON from 'date-fns/parseJSON';
import camelcaseKeys from 'camelcase-keys';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import formatISO from 'date-fns/formatISO';

import i18n from '@/i18n';
import {
  getMaterialsRequisitionNotesList,
  getAvailableInventories,
  getInventory,
} from '../api/materials-requisition-notes-api';

import { formatNumber as fn } from '../../../utils/number-formatting';

@Component
export default class extends Vue {
  allInventoryOptions: Array<any> = [];
  inventoryOptions: Array<any> = [];
  isInventoryOptionsLoading = false;

  formatNumber(input, dp) {
    return fn(input, dp);
  }

  sessionStorage = window.sessionStorage;
  localStorage = window.localStorage;

  dateInterval = [startOfMonth(new Date()), new Date()];
  datepickerShortcuts = [{
    text: 'Astăzi',
    onClick: () => [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    text: 'Ieri',
    onClick: () => [addDays(startOfDay(new Date()), -1), addDays(endOfDay(new Date()), -1)],
  },
  {
    text: 'Luna aceasta',
    onClick: () => [startOfMonth(new Date()), endOfMonth(new Date())],
  }, {
    text: 'Luna trecută',
    onClick: () => [addMonths(startOfMonth(new Date()), -1), endOfMonth(addMonths(new Date(), -1))],
  }, {
    text: 'Ultimele 3 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -2), endOfMonth(new Date())],
  }, {
    text: 'Ultimele 6 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -5), endOfMonth(new Date())],
  }, {
    text: 'Anul curent',
    onClick: () => [startOfYear(new Date()), endOfYear(new Date())],
  }, {
    text: 'Anul trecut',
    onClick: () => [addYears(startOfYear(new Date()), -1), addYears(endOfYear(new Date()), -1)],
  }];

  statusOptions = [
    { status: 1, label: 'Procesat' },
    { status: 0, label: 'Ciornă' },
    { status: 2, label: 'Anulat' },
  ];

  startDate = startOfMonth(new Date());
  endDate = new Date();
  inventory: any|null = null;
  status: any|null = null;

  async asyncSearchInventory() {
    if (this.allInventoryOptions?.length) {
      this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);
      return Promise.resolve();
    }

    this.isInventoryOptionsLoading = true;

    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);

    console.log(this.inventoryOptions, this.allInventoryOptions);

    this.isInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  debouncedSearchInventory = debounce(this.asyncSearchInventory, 75, { maxWait: 150 });

  addToGRNSessionStorage(key, value) {
    sessionStorage.setItem('mrn_grid_session_'.concat(key), value);
  }
  getFromGRNSessionStorage(key) {
    return sessionStorage.getItem('mrn_grid_session_'.concat(key)) || '';
  }
  addToGRNLocalStorage(key, value) {
    sessionStorage.setItem('mrn_grid_local_'.concat(key), value);
  }
  getFromGRNLocalStorage(key) {
    return sessionStorage.getItem('mrn_grid_local_'.concat(key)) || '';
  }

  onReportIntervalChange(val) {
    [this.startDate, this.endDate] = val;
    this.dateInterval = val;
  }

  hoveredRowIndex = -1;

  items: Array<any> = [];

  tableBusy = false;

  itemsPerPage = 25;
  currentGridPage = 1;
  allItemsCount = 0;

  editItemsPerPage = this.itemsPerPage;

  badgeVariant(statusCode) {
    switch (statusCode) {
      case 0:
        return 'secondary';
      case 1:
        return 'success';
      case 2:
        return 'warning';
      default:
        return 'secondary';
    }
  }

  async loadGridPage(page, perPage) {
    try {
      this.tableBusy = true;

      const listResult = camelcaseKeys(await getMaterialsRequisitionNotesList(page, perPage, {
        startDate: formatISO(this.dateInterval[0]),
        endDate: formatISO(this.dateInterval[1]),
        inventoryId: this.inventory?.inventoryId,
        statusCode: this?.status?.status,
      }));

      this.allItemsCount = listResult.count;
      this.items = listResult.items.map((x) => ({
        ...x,
        gestiune: '',
      }));
    } finally {
      this.tableBusy = false;
    }
  }

  async initialize() {
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  async created() {
    this.inventory = await getInventory(this.getFromGRNSessionStorage('inventoryId'));
    if (this.getFromGRNSessionStorage('startDate') && this.getFromGRNSessionStorage('endDate')) {
      this.dateInterval = [new Date(Date.parse(this.getFromGRNSessionStorage('startDate'))), new Date(Date.parse(this.getFromGRNSessionStorage('endDate')))];
    }
    this.status = this.getStatusOptionObject(this.getFromGRNSessionStorage('status'));
    if (this.getFromGRNLocalStorage('itemsPerPage')) {
      this.itemsPerPage = parseInt(this.getFromGRNLocalStorage('itemsPerPage'), 10);
      this.editItemsPerPage = this.itemsPerPage;
    }
    this.initialize();
  }

  async onApplyFilters() {
    if (!this.editItemsPerPage) {
      this.editItemsPerPage = 25;
    }
    this.itemsPerPage = this.editItemsPerPage;
    this.storeFilters();
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  async onApplyItemsPerPage() {
    if (!this.editItemsPerPage) {
      this.editItemsPerPage = 25;
    }
    this.itemsPerPage = this.editItemsPerPage;
    this.storeFilters();
    await this.loadGridPage(1, this.itemsPerPage);
  }

  async onCurrentGridPageChanged(page) {
    this.currentGridPage = page;
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  storeFilters() {
    this.addToGRNSessionStorage('inventoryId', this.inventory?.inventoryId);
    this.addToGRNSessionStorage('startDate', formatISO(this.dateInterval[0]));
    this.addToGRNSessionStorage('endDate', formatISO(this.dateInterval[1]));
    this.addToGRNSessionStorage('status', this.status?.status);
    this.addToGRNLocalStorage('itemsPerPage', this.itemsPerPage);
  }

  getStatusOptionObject(status) {
    return this.statusOptions.filter((option) => option.status === parseInt(status, 10))[0];
  }

  rowHovered(_: unknown, index: number) {
    this.hoveredRowIndex = index;
  }

  rowUnhovered() {
    this.hoveredRowIndex = -1;
  }

  get parseJSON() { return parseJSON; }

  get fields() {
    return [
      {
        key: 'numar',
        label: i18n.t('mrn.grid.numar'),
      },
      {
        key: 'data',
        label: i18n.t('mrn.grid.data'),
      },
      {
        key: 'gestiune',
        label: i18n.t('mrn.grid.inventories'),
      },
      {
        key: 'gestionar',
        label: i18n.t('mrn.grid.releasedBy'),
      },
      {
        key: 'stare',
        label: i18n.t('mrn.grid.stare'),
      },
      {
        key: 'actiuni',
        label: i18n.t('mrn.grid.actiuni'),
        thClass: 'col-action',
        tdClass: 'col-action-body',
      },
    ];
  }
}
