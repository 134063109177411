const httpClient = require('@/services/http-client');

export async function lookupInventory(lookupTerm) {
  const response = await httpClient.get('/api/lookup/inventory', {
    params: {
      lookupTerm,
    },
  });

  return response.data;
}

export async function lookupProduct(lookupTerm) {
  const response = await httpClient.get('/api/lookup/product', {
    params: {
      lookupTerm,
    },
  });

  return response.data;
}

export async function getDocumentLogs(documentId) {
  const response = await httpClient.get(`/api/document-log-entry/mrn/${documentId}`);
  return response.data;
}

export async function getAvailableStock({
  lookupDate, productId, inventoryId, unitPrice = null,
}) {
  const response = await httpClient.get('/api/lookup/stock', {
    params: {
      lookupDate,
      productId,
      inventoryId,
      unitPrice,
    },
  });

  return response.data;
}

export async function getOnlyProductWithAvailableStock(payload) {
  const response = await httpClient.get('/api/adm/product-catalog/products-with-available-stocks', {
    params: {
      ...payload,
    },
  });

  return response.data;
}

export async function getAvailableInventories() {
  const response = await httpClient.get('/api/mrn/inventories');

  return response.data;
}

export async function getAvailableSeries(seriesYear) {
  const response = await httpClient.get('/api/mrn/series', {
    params: {
      seriesYear,
    },
  });

  return response.data;
}

export async function getMaterialsRequisitionNotesList(pageNumber, perPage, filters) {
  const response = await httpClient.get('/api/mrn/list', {
    params: {
      pageNumber: pageNumber || 1,
      perPage: perPage || 25,
      filters: JSON.stringify(filters),
    },
  });

  return response.data;
}

export async function saveDraft(draft) {
  const response = await httpClient.post('/api/mrn/draft', draft);

  return response.data;
}

export async function deleteDraft(id) {
  const response = await httpClient.delete(`/api/mrn/draft/${id}`);

  return response.data;
}

export async function deleteCanceled(id) {
  const response = await httpClient.delete(`/api/mrn/canceled/${id}`);

  return response.data;
}

export async function cancelDocument(id) {
  const response = await httpClient.post(`/api/mrn/cancel/${id}`);

  return response.data;
}

export async function restoreDocument(id) {
  const response = await httpClient.post(`/api/mrn/restore/${id}`);

  return response.data;
}

export async function postDraft(id) {
  const response = await httpClient.post(`/api/mrn/post/${id}`);

  return response.data;
}

export async function getDetails(id) {
  const response = await httpClient.get(`/api/mrn/details/${id}`);

  return response.data;
}

export async function compareLatestSeries(id) {
  const response = await httpClient.get(`/api/mrn/compare-latest-series/${id}`);

  return response.data;
}

export async function getPdf(id, numCopies) {
  const response = await httpClient.get(`/api/mrn/pdf/${id}`, {
    params: {
      copies: numCopies || 1,
      cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
    },
    // responseType: 'blob',
    responseType: 'arraybuffer',
    // This is in milliseconds, so wait up to 15 seconds
    timeout: 15 * 1000,
    headers: {
      Accept: 'application/octet-stream',
    },
  });

  return response.data;
}

export async function getInventory(inventoryId) {
  const response = await httpClient.get('/api/mrn/inventory/', {
    params: {
      inventoryId,
    },
  });

  return response.data;
}
